<template>
  <InfoWraper>
    <Notification />
    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click">
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <figure class="user-dropdwon__info">
                <!-- <img :src="require('../../../assets/img/avatar/chat-auth.png')" alt="" /> -->
                <a-avatar style="margin-right: 20px;" shape="square" :src="authUserAvatar">
                  <template #icon>
                    <sdFeatherIcons type="user" size="15" />
                  </template>
                </a-avatar>
                <figcaption>
                  <sdHeading as="h5">{{authUserName}}</sdHeading>
                  <!-- <p>UI Expert</p> -->
                </figcaption>
              </figure>
              <ul class="user-dropdwon__links">
                <li>
                  <a @click="navigateTo(`/${initial}/settings`)"> <sdFeatherIcons type="settings" /> Settings </a>
                </li>
              </ul>
              <a @click="SignOut" class="user-dropdwon__bottomAction" href="#"> <LogoutOutlined /> Sign Out </a>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar size="large" :src="authUserAvatar">
            <template #icon>
              <div style="padding-top: 4px;">
                <sdFeatherIcons type="user" size="20" />
              </div>
            </template>
          </a-avatar>
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
</template>

<script>
import {
  InfoWraper,
//  NavAuth,
  UserDropDwon
} from './auth-info-style';
import Notification from './Notification';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { LogoutOutlined } from '@ant-design/icons-vue';

export default {
  name: 'AuthInfo',
  components: {
    InfoWraper,
    // NavAuth,
    UserDropDwon,
    Notification,
    LogoutOutlined,
  },
  computed: {
    authUserName: function() {
      String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
      }
      const name = this.$store?.getters?.authUser?.name ?? "";
      return name.capitalize();
    },
    authUserAvatar: function() {
      return this.$store?.getters?.authUser?.avatar ?? "";
    },
    initial: function() {
      return this.$store?.getters?.initial;
    }
  },
  data() {
    return {
      flag: 'english',
    };
  },
  setup() {
    const { dispatch } = useStore();
    const { replace } = useRouter();
    const SignOut = e => {
      e.preventDefault();
      replace('/portal/login');
      dispatch('logOut');
    };
    return {
      SignOut,
    };
  },
  methods: {
    onFlagChangeHandle: function(value) {
      this.flag = value;
    },
    navigateTo: function (path = "") {
      if (path) {
        this.$router.replace({path});
      }
    },
  },
};
</script>
