<template>
  <div class="notification" @click="readAllNotification">
    <sdPopover ref="popover" placement="rightBottom" action="click">
      <template v-slot:content>
        <AtbdTopDropdwon class="atbd-top-dropdwon">
          <!-- <sdHeading as="h5" class="atbd-top-dropdwon__title">
            <span class="title-text">Notifications</span>
            <a-badge class="badge-success" :count="notifications.length" />
          </sdHeading> -->
          <div v-if="notifications.length > 0">
            <ul class="atbd-top-dropdwon__nav notification-list">
              <li v-for="item of notifications" :key="item.id">
                <a to="#" @click="onNotificationClick(item)">
                  <div class="atbd-top-dropdwon__content notifications">
                    <div class="notification-icon bg-primary">
                      <sdFeatherIcons type="hard-drive" />
                    </div>
                    <div class="notification-content d-flex">
                      <div class="notification-text">
                        <sdHeading as="h5">{{ item.description }} </sdHeading>
                        <p>{{ getFormatedDateTime(item.created_at) }}</p>
                      </div>
                      <div class="notification-status">
                        <a-badge dot />
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="empty-notification-list" v-else>
            <sdFeatherIcons type="bell" size="100" />
            <span>Notification not found</span>
          </div>
          <!-- <router-link class="btn-seeAll" to="#">
            See all incoming activity
          </router-link> -->
        </AtbdTopDropdwon>
      </template>
      <a-badge :count="readCount" :offset="[-8, -7]">
        <a to="#" class="head-example">
          <sdFeatherIcons type="bell" size="20" @click="changeNotification()" />
        </a>
      </a-badge>
    </sdPopover>
  </div>
</template>
<script>
import moment from "moment";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { AtbdTopDropdwon } from "./auth-info-style";
import { API } from "@/services/api";
import Notif from "@/services/Notification";

export default {
  name: "Notification",
  components: {
    AtbdTopDropdwon,
  },
  computed: {
    initial: function () {
      return this.$store.getters.initial;
    },
    popover: function () {
      return this.$refs.popover;
    },
  },
  data() {
    return {
      notifications: [],
      readCount: 0,
      interval: null,
    };
  },
  methods: {
    onNotificationClick(notification) {
      this.readNotification(notification.encrypt_id);
      this.navigateTo(
        `/${this.initial}/participants/details/${notification.participant_id}`
      );
    },
    navigateTo: function (path = "") {
      if (path) {
        if (this.popover) this.popover.hidePopover();
        this.$router.replace({ path });
      }
    },
    async getNotification() {
      if (this.initial == "admin") return null;

      const id = this.$store?.getters?.authUser?.id ?? "";
      const params = { user_id: id };
      const res = await API.getNotifications(
        { params },
        this.initial == "admin" ? "admin" : "user"
      );

      const { data = [] } = res.data;
      this.notifications = data;
      this.readCount = this.notifications.filter((c) => !c?.is_read).length;
    },
    async readAllNotification() {
      if (this.initial == "admin") return null;
      try {
        await API.readAllNotification(
          this.initial == "admin" ? "admin-read" : "user-read"
        );
        this.readCount = 0;
      } catch (error) {
        if (error?.message) {
          Notif.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
    getFormatedDateTime(date = "") {
      return moment(date).fromNow();
    },
    changeNotification() {
      this.getNotification();
    },
    async readNotification(id) {
      try {
        await API.readNotification(
          { params: { id } },
          this.initial == "admin" ? "admin-read" : "user-read"
        );
        this.notifications = this.notifications.filter(
          (n) => n.encrypt_id != id
        );
        this.readCount = this.notifications.filter((c) => !c?.is_read).length;
      } catch (error) {
        if (error?.message) {
          Notif.show(error.message, { type: "error", title: "Error" });
        }
      }
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.getNotification();
    }, 5000);
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>
<style scoped>
.ps {
  height: 200px;
}
</style>
